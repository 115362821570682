import React, { useCallback, useEffect, useState } from 'react';
import {
    Paper,
    Typography,
    Box,
    Collapse,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    TextField, Button, MenuItem, InputLabel, Select, FormControl, Grid
} from "@mui/material";
import { useSelector } from "react-redux";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./allOederListForAdmin.css"
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// Tarih formatlayıcı fonksiyon
const formatOrderTime = (orderTime) => {
    if (!orderTime) return 'Tarih mevcut değil';
    const date = new Date(orderTime);
    return new Intl.DateTimeFormat('tr-TR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    }).format(date);
};
const AllOrderListForAdmin = () => {
    const [activeButton, setActiveButton] = useState('table');
    const [orderedList, setOrderedList] = useState([]);
    const [expanded, setExpanded] = useState(null);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [startDate, setStartDate] = useState(null); // Başlangıç tarihi
    const [endDate, setEndDate] = useState(null); // Bitiş tarihi
    const [filterOption, setFilterOption] = useState(''); // Günlük, Haftalık, vs. için seçenek
    const [customerNameFilter, setCustomerNameFilter] = useState(''); // Müşteri adı filtresi
    const [orderStatusChanges, setOrderStatusChanges] = useState({});
    const token = useSelector((state) => state.token.value);
    const userId = token?.userId;

    const handleButtonClick = (button) => {
        setActiveButton(button);
    };

    const fetchBranches = useCallback(async () => {
        try {
            const response = await fetch(`/order/get-list-all`, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${token.token}`
                }
            });

            if (!response.ok) {
                throw new Error("Failed to fetch branches");
            }

            const data = await response.json();
            setOrderedList(data);
            setFilteredOrders(data)
        } catch (error) {
            console.error("Error fetching branches:", error);
        }
    }, [userId, token]);

    useEffect(() => {
        fetchBranches();
    }, [fetchBranches]);

    const handleExpandClick = (id) => {
        setExpanded(expanded === id ? null : id); // Açılır kapanır (collapse) işlemi için
    };

    const handleOrderStatusChange = (orderId, newStatus) => {
        setOrderStatusChanges((prev) => ({
            ...prev,
            [orderId]: newStatus,
        }));
    };

    const calculateDateRange = (option) => {
        const now = new Date();
        let newStartDate, newEndDate;

        switch (option) {
            case 'daily':
                newStartDate = new Date(now.setHours(0, 0, 0, 0)); // Günün başlangıcı
                newEndDate = new Date(now.setHours(23, 59, 59, 999)); // Günün sonu
                break;
            case 'weekly':
                const firstDayOfWeek = now.getDate() - now.getDay(); // Haftanın ilk günü
                newStartDate = new Date(now.setDate(firstDayOfWeek)); // Haftanın ilk günü
                newStartDate.setHours(0, 0, 0, 0); // Günü sıfırla
                newEndDate = new Date(now.setDate(firstDayOfWeek + 6)); // Haftanın son günü
                newEndDate.setHours(23, 59, 59, 999); // Günü bitir
                break;
            case 'monthly':
                newStartDate = new Date(now.getFullYear(), now.getMonth(), 1); // Ayın ilk günü
                newStartDate.setHours(0, 0, 0, 0);
                newEndDate = new Date(now.getFullYear(), now.getMonth() + 1, 0); // Ayın son günü
                newEndDate.setHours(23, 59, 59, 999);
                break;
            case 'yearly':
                newStartDate = new Date(now.getFullYear(), 0, 1); // Yılın ilk günü
                newStartDate.setHours(0, 0, 0, 0);
                newEndDate = new Date(now.getFullYear(), 11, 31); // Yılın son günü
                newEndDate.setHours(23, 59, 59, 999);
                break;
            default:
                newStartDate = null;
                newEndDate = null;
                break;
        }

        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };


    // Tarih aralığına göre siparişleri filtreleyen fonksiyon
    // handleDateFilter fonksiyonu:
    const handleDateFilter = () => {
        if (filterOption) {
            calculateDateRange(filterOption); // Seçilen filtreye göre tarihleri ayarlıyoruz
        }

        let filtered = orderedList;

        // Tarih aralığına göre siparişleri filtreleme
        if (startDate && endDate) {
            const start = new Date(startDate).setHours(0, 0, 0, 0);
            const end = new Date(endDate).setHours(23, 59, 59, 999);

            filtered = filtered.filter(order => {
                const orderDate = new Date(order.orderTime);
                return orderDate >= start && orderDate <= end; // İki tarih arası filtreleme
            });
        } else if (startDate && !endDate) {
            // Sadece başlangıç tarihi varsa
            const start = new Date(startDate).setHours(0, 0, 0, 0);
            filtered = filtered.filter(order => {
                const orderDate = new Date(order.orderTime);
                return orderDate >= start;
            });
        }

        // Müşteri adı filtresi
        if (customerNameFilter) {
            filtered = filtered.filter(order =>
                order.restaurantName.toLowerCase().includes(customerNameFilter.toLowerCase())
            );
        }

        setFilteredOrders(filtered); // Filtrelenmiş siparişleri güncelle
    };

    // Filtreleri sıfırlayan fonksiyon
    const clearFilters = () => {
        setStartDate(null);
        setEndDate(null);
        setFilterOption('');
        setFilteredOrders(orderedList); // Filtreyi kaldırınca tüm siparişleri göster
    };


    const handleUpdateClick = async (orderId) => {
        if (!orderStatusChanges[orderId]) return;

        try {
            const response = await fetch(`/order/update-order-status`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${token.token}`
                },
                body: JSON.stringify({
                    orderId: orderId,
                    newStatus: orderStatusChanges[orderId],
                }),
            });

            if (!response.ok) {
                throw new Error("Failed to update order status");
            }

            // Güncellendikten sonra status değişikliğini temizle
            setOrderStatusChanges((prev) => {
                const updated = { ...prev };
                delete updated[orderId];
                return updated;
            });

            fetchBranches()

            console.log("Order status updated successfully.");
        } catch (error) {
            console.error("Error updating order status:", error);
        }
    };


    return (
        <Box sx={{
            flexGrow: 1,
            padding: 2,
            background: '#F4F1DE',
            height: '100%',
            minHeight: '100vw',
            paddingTop: "5vh",
            color: 'black'
        }}>
            <Paper sx={{
                padding: 2,
                height: 'auto',
                backgroundColor: '#ffffff',
                boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
                border: '2px solid #F4F1DE',
                color: '#000000',
                marginBottom: '20px'
            }}>
                <Typography variant="h6" component="div">
                    Tum sifarisleriniz
                </Typography>


                {/* Tarih filtrelemesi ve seçenek kutusu */}
                <Grid container spacing={2} sx={{ marginTop: 2 }}>
                    <Grid item xs={12} className="ordered-list-tablo-list-choose-button">
                        <button
                            className={`toggle-button-list ${activeButton === 'list' ? 'active' : ''}`}
                            style={{width:"100%", height:"100%"}}
                            onClick={() => handleButtonClick('all')}
                        >
                            Hamısı
                        </button>
                        <button
                            className={`toggle-button ${activeButton === 'table' ? 'active' : ''}`}
                            style={{width:"100%", height:"100%"}}
                            onClick={() => handleButtonClick('partner')}
                        >
                            Partner
                        </button>
                        <button
                            className={`toggle-button ${activeButton === 'table' ? 'active' : ''}`}
                            style={{width:"100%", height:"100%"}}
                            onClick={() => handleButtonClick('user')}
                        >
                            İstifadəçi
                        </button>
                    </Grid>
                    <Grid item xs={12} className="ordered-list-tablo-list-choose-button">
                        <button
                            className={`toggle-button-list ${activeButton === 'list' ? 'active' : ''}`}
                            style={{width:"100%", height:"100%"}}
                            onClick={() => handleButtonClick('list')}
                        >
                            Liste
                        </button>
                        <button
                            className={`toggle-button ${activeButton === 'table' ? 'active' : ''}`}
                            style={{width:"100%", height:"100%"}}
                            onClick={() => handleButtonClick('table')}
                        >
                            Tablo
                        </button>
                    </Grid>
                    <Grid item xs={12} sm={2.4}>
                        <TextField
                            label="Paerner veya İstifadəçi Adı"
                            value={customerNameFilter}
                            onChange={(e) => setCustomerNameFilter(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={2.4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Başlangıç Tarihi"
                                value={startDate}
                                onChange={(newValue) => setStartDate(newValue)}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={2.4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Bitiş Tarihi"
                                value={endDate}
                                onChange={(newValue) => setEndDate(newValue)}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={2.4}>
                        <FormControl fullWidth>
                            <InputLabel>Filtre Seçimi</InputLabel>
                            <Select
                                value={filterOption}
                                onChange={(e) => setFilterOption(e.target.value)}
                            >
                                <MenuItem value="daily">Günlük</MenuItem>
                                <MenuItem value="weekly">Haftalık</MenuItem>
                                <MenuItem value="monthly">Aylık</MenuItem>
                                <MenuItem value="yearly">Yıllık</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2.4} style={{display:"flex", justifyContent:"center", alignItems:"center"}}>

                        <button className="qapinda-button"
                                style={{width: "100%", height: "100%"}}
                                onClick={handleDateFilter}
                        >
                            Filtrele
                        </button>
                        <button className="qapinda-button-second"
                                style={{width: "100%", height: "100%", marginLeft:"5px"}}
                                onClick={clearFilters}
                        >
                            Filtreyi kaldir
                        </button>

                    </Grid>
                </Grid>
                {/* Eğer aktif olan buton "list" ise collapse tarzında göster */}
                {activeButton === 'list' && (
                    <div>
                        {filteredOrders.map((order) => (
                            <div key={order.orderId}>
                                <Box
                                    sx={{
                                        marginTop: 2,
                                        border: '1px solid #ddd',
                                        padding: 2,
                                        borderRadius: '8px',
                                        background: '#fff',
                                    }}
                                >
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="subtitle1" style={{fontWeight:"bold"}}>{order.restaurantName}</Typography>
                                        <IconButton onClick={() => handleExpandClick(order.orderId)}>
                                            <ExpandMoreIcon />
                                        </IconButton>
                                    </div>
                                    <Collapse in={expanded === order.orderId} timeout="auto" unmountOnExit>
                                        <Typography><strong>Adres:</strong> {order.customerAddress}</Typography>
                                        <Typography><strong>Telefon:</strong> {order.customerPhoneNumber}</Typography>
                                        <Typography><strong>Musteri adi:</strong> {order.customerName}</Typography>
                                        <Typography><strong>Sifaris Tarihi:</strong> {formatOrderTime(order.orderTime)}</Typography>
                                        <Typography><strong>Fiyat:</strong> {order.orderPrice} AZN</Typography>
                                        <Typography><strong>Ödeme Yöntemi:</strong> {order.paymentMethod}</Typography>
                                        <Typography style={{display:"flex", alignItems:"center"}}>
                                            <strong>Sifaris Durumu:</strong>
                                            <TableCell>
                                                <FormControl fullWidth>
                                                    <Select
                                                        className={order.orderStatus === `ORDER_NOT_ASSIGNED` ? `ORDER_NOT_ASSIGNED` : order.orderStatus === `ORDER_ASSIGNED` ? `ORDER_ASSIGNED` : `ORDER_DELIVERED` }
                                                        value={orderStatusChanges[order.orderId] || order.orderStatus}
                                                        onChange={(e) => handleOrderStatusChange(order.orderId, e.target.value)}
                                                    >
                                                        <MenuItem value="ORDER_NOT_ASSIGNED">Sipariş Atanmadı</MenuItem>
                                                        <MenuItem value="ORDER_ASSIGNED">Sipariş Atandı</MenuItem>
                                                        <MenuItem value="ORDER_DELIVERED">Sipariş Teslim Edildi</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                        </Typography>
                                        <Typography><strong>Acıklama:</strong> {order.deliveryInstruction}</Typography>
                                        <button
                                            className={orderStatusChanges[order.orderId] ? `qapinda-button` : `qapinda-button-second`}
                                            style={{
                                                width: "290px",
                                                cursor: !orderStatusChanges[order.orderId] ? 'not-allowed' : 'pointer' // Cursor tıklanamaz olduğunda değişiyor
                                            }}
                                            onClick={() => handleUpdateClick(order.orderId)}
                                            disabled={!orderStatusChanges[order.orderId]}
                                        >
                                            Güncelle
                                        </button>
                                    </Collapse>
                                </Box>
                            </div>
                        ))}
                    </div>
                )}

                {/* Eğer aktif olan buton "table" ise tablo tarzında göster */}
                {activeButton === 'table' && (
                    <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Müşteri Adı</TableCell>
                                    <TableCell>Adres</TableCell>
                                    <TableCell>Telefon</TableCell>
                                    <TableCell>Sifaris Tarihi:</TableCell>
                                    <TableCell>Fiyat</TableCell>
                                    <TableCell>Ödeme Yöntemi</TableCell>
                                    <TableCell>Sifaris Durumu:</TableCell>
                                    <TableCell>Acıklama</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredOrders.map((order) => (
                                    <TableRow key={order.orderId}>
                                        <TableCell>{order.customerName}</TableCell>
                                        <TableCell>{order.customerAddress}</TableCell>
                                        <TableCell>{order.customerPhoneNumber}</TableCell>
                                        <TableCell>{formatOrderTime(order.orderTime)}</TableCell>
                                        <TableCell>{order.orderPrice} AZN</TableCell>
                                        <TableCell>{order.paymentMethod}</TableCell>
                                        {/*<TableCell>{order.orderStatus}</TableCell>*/}
                                        <TableCell>
                                            <FormControl fullWidth>
                                                <Select
                                                    className={order.orderStatus === `ORDER_NOT_ASSIGNED` ? `ORDER_NOT_ASSIGNED` : order.orderStatus === `ORDER_ASSIGNED` ? `ORDER_ASSIGNED` : `ORDER_DELIVERED` }
                                                    value={orderStatusChanges[order.orderId] || order.orderStatus}
                                                    onChange={(e) => handleOrderStatusChange(order.orderId, e.target.value)}
                                                >
                                                    <MenuItem value="ORDER_NOT_ASSIGNED">Sipariş Atanmadı</MenuItem>
                                                    <MenuItem value="ORDER_ASSIGNED">Sipariş Atandı</MenuItem>
                                                    <MenuItem value="ORDER_DELIVERED">Sipariş Teslim Edildi</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell>{order.deliveryInstruction}</TableCell>
                                        <TableCell>
                                            <button
                                                className={orderStatusChanges[order.orderId] ? `qapinda-button` : `qapinda-button-second`}
                                                style={{
                                                    width: "100%",
                                                    cursor: !orderStatusChanges[order.orderId] ? 'not-allowed' : 'pointer' // Cursor tıklanamaz olduğunda değişiyor
                                                }}
                                                onClick={() => handleUpdateClick(order.orderId)}
                                                disabled={!orderStatusChanges[order.orderId]}
                                            >
                                                Güncelle
                                            </button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Paper>
        </Box>
    );
};

export default AllOrderListForAdmin;