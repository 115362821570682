import {styled} from "@mui/material/styles";
import {TextField} from "@mui/material";

const CustomTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        borderRadius: '10px', // Kenar yuvarlaklığını ayarla
        boxShadow: '1px 1px 1px 1px rgba(222, 105, 105, 0.32)', // Pembe renkli gölge ekle
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent', // Varsayılan border rengini kaldır
    }
});


export default CustomTextField;