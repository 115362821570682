import React from 'react';
import FirstPage from "./FirstPage";
import SecondPage from "./SecondPage";
import ThirdPage from "./ThirdPage";
import Canvas from "../../design-hook/Canvas.tsx";
import FirthCalculatePage from "./FirthCalculatePage";
import FifthWhyUs from "./FifthWhyUs";
import SixthBecameCourier from "./SixthBecameCourier";

const Landing = ({isMobile}) => {
    return (
        <div>
            <div id="home" className="App"><FirstPage isMobile={isMobile}/></div>
            <Canvas />
            <div id="partner" className="App"><SecondPage/></div>
            <Canvas />
            <div id="register" className="App"><ThirdPage/></div>
            <Canvas />
            <div id="calculate" className="App"><FirthCalculatePage/></div>
            <Canvas />
            <div id="about" className="App"><FifthWhyUs/></div>
            <Canvas />
            <div id="courier" className="App"><SixthBecameCourier/></div>
            <div style={{height:"200px"}}></div>
        </div>
    );
};

export default Landing;