import React, {useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {postFetch} from "../../network";
import {setter} from "../../tokenSlice";
import {Alert, Box, Card, Grid, IconButton, InputAdornment, Snackbar, TextField} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import "./login.css"
import EmailIcon from "@mui/icons-material/Email";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import CustomTextField from "../../component/custom-field/CustomTextField";



// Özelleştirilmiş TextField bileşeni


const LoginCard = () => {
    const token = useSelector((state) => state.token.value)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const recaptchaRef = useRef(null);
    const [showPassword, setShowPassword] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [toastSeverity, setToastSeverity] = useState('success');
    const [isSubmitting, setIsSubmitting] = useState(false); // Form gönderilirken butonu disable etmek için
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [result, setResult] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true)

        // const recaptchaValue = recaptchaRef.current.getValue();  // reCAPTCHA değerini al
        // if (!recaptchaValue) {
        //     setResult("Lütfen reCAPTCHA'yı doğrulayın.");
        //     return;
        // }

        const payload = {
            email: email,
            password: password,
            // recaptcha: recaptchaValue
        };

        // API endpoint belirleniyor
        const baseURL = '/api/v1/signin';

        try {
            const response = await fetch(baseURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            const res = await response.json();

            console.log("res: ", res);

            // HTTP response ok durumunu kontrol et
            if (!response.ok) {
                throw new Error(res.message || "Bir hata oluştu");
            }

            if (res.token) {
                dispatch(setter({token: res.token, role: res.role, userId: res.userId}));
                // Token'i localStorage'a kaydet
                localStorage.setItem('token', res.token);
                localStorage.setItem('role', res.role);
                localStorage.setItem('userId', res.userId);
                setResult(`token: ${res.token}, role: ${res.role}, userId: ${res.userId}`);
                navigate('/main');
            } else {
                throw new Error("Token alınamadı");
            }
        } catch (error) {
            console.error("Hata: ", error);
            setResult(error.message);
            setSnackbarMessage(error.message);
            setToastSeverity('error');
            setOpenSnackbar(true);
            setIsSubmitting(false)
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleCloseSnackbar = () => setOpenSnackbar(false);

    const isDisable = () => {
        return  !email || isSubmitting || !password;
    };


    return (
        <Box>
            <Card style={{
                padding: '20px',
                borderRadius: '20px',
                boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
                backgroundColor: '#fff',  // Kart arka planı beyaz
                width: '400px',  // Kart genişliği
                textAlign: 'center'  // Metin ortalanmış
            }}>
                <div className="xos-gelmisiz-background-text-background">
                    <div className="Logo" onClick={() => navigate("/")} style={{paddingBottom: "15px"}}>
                        Qapında
                    </div>
                    <div className="xos-gelmisiz-background-text">Xoş gəlmisiniz!</div>
                    <div className="xos-gelmisiz-background-text-second">Giriş məlumatlarınızı doldurun</div>
                </div>

                <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
                    <CustomTextField
                        label="Email və ya istifadəçi adı"
                        variant="outlined"
                        type='text'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <EmailIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <CustomTextField
                        label="Şifrə"
                        variant="outlined"
                        type={!showPassword ? `password` : ``}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {!showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6Lclil8qAAAAABQXd_n32hhNlyYFj24m7XdJ3VKo"  // Buraya kendi site anahtarınızı yazın
                        size="normal"
                        onChange={token => console.log("Captcha value:", token)}
                    />
                    <button type='submit' className="qapinda-button"
                            style={{
                                marginTop:"20px",
                                width: "100%",
                                backgroundColor: isDisable() ? '#ccc' : '#f00', // Disable olduğunda gri, aktif olduğunda kırmızı
                                cursor: isDisable() ? 'not-allowed' : 'pointer' // Cursor tıklanamaz olduğunda değişiyor
                            }}
                            disabled={isDisable()}
                    >Giriş</button>
                </form>

                <div className="hesabiniz-yoxdu-background">
                    <div className="hesabiniz-yoxdu-background-text">Hesabınız yoxdur?</div>
                    <div className="hesabiniz-yoxdu-background-text-link"
                         onClick={() => navigate("/register")}>Qeydiyyatdan keç!
                    </div>
                </div>
                <div className="hesabiniz-yoxdu-background-patnyor">
                    <div className="hesabiniz-yoxdu-background-text">Əməkdaşlıq etmək istəyirsiniz?
                    </div>
                    <div className="hesabiniz-yoxdu-background-text-link-register"
                         onClick={() => navigate("/partnyor")}>Partnyor ol
                    </div>
                </div>

                <div className="copyright">
                    Copyright 2024 Qapinda
                </div>
            </Card>
            <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={toastSeverity} sx={{ width: '100%', position:"center"}}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    )
};

export default LoginCard;