import React, {useState} from 'react';
import {Grid, Avatar, List, ListItem, Popover} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import "./header.css";
import HomeIcon from "@mui/icons-material/Home";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import HistoryIcon from "@mui/icons-material/History";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import PrintIcon from "@mui/icons-material/Print";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {setter} from "../../tokenSlice";
import {useDispatch} from "react-redux";

const WebHeader = ({token, setHeaderStep}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        dispatch(setter({ token: null, role: null, userId:null }));
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        localStorage.removeItem('userId');
        navigate('/login');
        window.location.reload();
        handleClose();
    };

    const listItemsAuthenticatedPartner = [
        { icon: <HomeIcon sx={{ color: 'red' }} />, text: 'Ana Sehife', path: '/main' },
        { icon: <StoreMallDirectoryIcon sx={{ color: 'red' }} />, text: 'Şöbelerim', path: '/branches' },
        { icon: <HistoryIcon sx={{ color: 'red' }} />, text: 'Geçmiş Sifarişler', path: "/old-orders" },
        { icon: <AddShoppingCartIcon sx={{ color: 'red' }} />, text: 'Sifariş Ver', path: "/create-order" },
        { icon: <ContactMailIcon sx={{ color: 'red' }} />, text: 'Bizimle Elaqe', path: "/contact-us" },
        { icon: <PrintIcon sx={{ color: 'red' }} />, text: 'Z-raporu', path: "z-report" },
    ];

    const listItemsAuthenticatedUser = [
        {icon: <HomeIcon sx={{color: 'red'}}/>, text: 'Ana Sehife', path: '/profile'},
        {icon: <HistoryIcon sx={{color: 'red'}}/>, text: 'Geçmiş Sifarişler', path: "story"},
        {icon: <AddShoppingCartIcon sx={{color: 'red'}}/>, text: 'Sifariş Ver', path: "story"},
        {icon: <ContactMailIcon sx={{color: 'red'}}/>, text: 'Bizimle Elaqe', path: "story"},
    ];

    const listItemsAuthenticatedAdmin = [
        // {icon: <HomeIcon sx={{color: 'red'}}/>, text: 'Ana Sehife', path: '/profile'},
        {icon: <HomeIcon sx={{color: 'red'}}/>, text: 'Sifarisler', path: '/old-orders'},
        {icon: <HomeIcon sx={{color: 'red'}}/>, text: 'Partnyor ekle', path: '/add-customer'},
        {icon: <HomeIcon sx={{color: 'red'}}/>, text: 'İstifadəçi', path: '/all-user'},
        {icon: <HomeIcon sx={{color: 'red'}}/>, text: 'Mesajlar', path: '/contact-us/reported-list'}
    ];

    const listItemsUnAuthenticated = [
        {icon: <HomeIcon sx={{color: 'red'}}/>, text: 'Ana Səhifə', path: 'home'},
        {icon: <ContactMailIcon sx={{color: 'red'}}/>, text: 'Partnyor Ol', path: 'partner'},
        {icon: <AddShoppingCartIcon sx={{color: 'red'}}/>, text: 'Fərdi qeydiyyat', path: 'register'},
        {icon: <PrintIcon sx={{color: 'red'}}/>, text: 'Qiyməti hesabla', path: 'calculate'},
        {icon: <HistoryIcon sx={{color: 'red'}}/>, text: 'Haqqımızda', path: 'about'},
        {icon: <HistoryIcon sx={{color: 'red'}}/>, text: 'Kuryerimiz olun', path: 'courier'},
    ];

    return (
        <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={2} container justifyContent="flex-start">
                <div className="Logo" onClick={() => navigate("/")}>
                    Qapında
                </div>
            </Grid>

            <Grid item xs={8} container justifyContent="center">
                {token?.role === "ROLE_CUSTOMER" ?
                    listItemsAuthenticatedPartner?.map((item, index) => (
                        <ListItemButton key={index} onClick={() => navigate(item?.path)}>
                            <ListItemText primary={item.text}/>
                        </ListItemButton>
                    )) : token?.role === "ROLE_USER" ? listItemsAuthenticatedUser?.map((item, index) => (
                        <ListItemButton key={index} onClick={() => navigate(item?.path)}>
                            <ListItemText primary={item.text}/>
                        </ListItemButton>
                    )) : token?.role === "ROLE_ADMIN" ? listItemsAuthenticatedAdmin?.map((item, index) => (
                            <ListItemButton key={index} onClick={() => navigate(item?.path)}>
                                <ListItemText primary={item.text}/>
                            </ListItemButton>
                        )) : (currentPath !== "/register" && currentPath !== "/login" && currentPath !== "/partnyor") ?
                        <div className="list-container">
                            {listItemsUnAuthenticated?.map((item, index) => (
                                <div className="list-container-text-padding" key={index} onClick={() => {
                                    if (currentPath !== "/") {
                                        navigate("/")
                                    }
                                    document.getElementById(item.path).scrollIntoView({behavior: 'smooth'})
                                }}>
                                    <div className="custom-list-item-text">{item.text}</div>
                                </div>
                            ))}
                        </div>: ""
                }
            </Grid>

            <Grid item xs={2} container justifyContent="flex-end">
                {!token ?
                    <>
                        <button className="qapinda-button" onClick={() => navigate("/login")}>Giriş</button>
                        <button className="qapinda-button-second" onClick={() => navigate("/partnyor")} style={{marginLeft:"10px"}}>Partnyor ol</button>
                    </>
                    :
                    <>
                        <Avatar sx={{bgcolor: 'red'}}
                                onClick={handleClick}>{token.token.charAt(0).toUpperCase()}</Avatar>
                        <Popover
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <List>
                                <ListItem button style={{cursor:"pointer"}} onClick={() => navigate("/profile")}>
                                    <ListItemIcon>
                                        <AccountCircleIcon sx={{ color: 'red'}}/>
                                    </ListItemIcon>
                                    <ListItemText primary="Profil" />
                                </ListItem>
                                <ListItem button  style={{cursor:"pointer"}} onClick={handleLogout}>
                                    <ListItemIcon>
                                        <ExitToAppIcon  sx={{ color: 'red' }}/>
                                    </ListItemIcon>
                                    <ListItemText primary="Çıxış" />
                                </ListItem>
                            </List>
                        </Popover>
                    </>
                }
            </Grid>
        </Grid>
    );
};

export default WebHeader;