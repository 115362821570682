import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import {setter} from '../../tokenSlice'
import {useNavigate} from 'react-router-dom';
import {Alert, Box, Card, Grid, Snackbar} from "@mui/material";
import CustomTextField from "../../component/custom-field/CustomTextField";

const Register = () => {
    const token = useSelector((state) => state.token.value)
    const dispatch = useDispatch()
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false); // Form gönderilirken butonu disable etmek için
    const [errors, setErrors] = useState({});
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [toastSeverity, setToastSeverity] = useState('success');
    const [result, setResult] = useState('')
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();
        const baseURL = '/api/v1';

        setIsSubmitting(true); // Form gönderilirken butonu disable yap

        const payload = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            password: password
        };

        fetch(`${baseURL}/signup`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {'Content-type': 'application/json'}
        })
            .then(response => response.json())
            .then(data => {
                console.log("data: ", data);
                if (data?.token) {
                    console.log("hata yok")
                    dispatch(setter(data.token));
                    setSnackbarMessage("Kayıt Başarılı!");
                    setToastSeverity('success');
                    setOpenSnackbar(true);
                    setIsSubmitting(false);
                    navigate('/login');
                } else if(data?.message) {
                    console.log("hata var")
                    setOpenSnackbar(true);
                    setIsSubmitting(false);
                    setToastSeverity('error');
                    setSnackbarMessage(data.message);
                }
            })
    };

    const handleCloseSnackbar = () => setOpenSnackbar(false);

    const isDisable = () => {
        return !firstName || !lastName || !email || isSubmitting || !phone || !password;
    };

    return (
        <Grid container spacing={1} className="box-background">
        <Grid item xs={12} sm={12} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Box>
                <Card style={{
                    padding: '20px',
                    borderRadius: '20px',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
                    backgroundColor: '#fff',  // Kart arka planı beyaz
                    width: '400px',  // Kart genişliği
                    textAlign: 'center'  // Metin ortalanmış
                }}>
                    <div className="xos-gelmisiz-background-text-background">
                        <div className="Logo" onClick={() => navigate("/")} style={{paddingBottom: "15px"}}>
                            Qapında
                        </div>
                        <div className="xos-gelmisiz-background-text">Xoş gəlmisiniz!</div>
                        <div className="xos-gelmisiz-background-text-second">Fərdi qeydiyyatdan keçmək üçün məlumatlarınızı doldurun, çox yaxında əməkdaşlarımız sizə doğrulama üçün zəng edəcək. Doğrulamadan sonra giriş edə bilərsiniz.</div>
                    </div>
                        <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
                            <CustomTextField
                                label="Ad"
                                type='text'
                                variant="outlined"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                            />
                            <CustomTextField
                                type='text'
                                value={lastName}
                                variant="outlined"
                                onChange={(e) => setLastName(e.target.value)}
                                label="Soyad"
                                required
                            />
                            <CustomTextField
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                label="E-poçt və ya istifadəçi adı"
                                required
                            />
                            <CustomTextField
                                type='phone'
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                label="Telefon"
                                required
                            />
                            <CustomTextField
                                type='password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                label="Şifrə"
                                required
                            />
                            <button type='submit' className="qapinda-button"
                                    style={{
                                        marginTop:"20px",
                                        width: "100%",
                                        backgroundColor: isDisable() ? '#ccc' : '#f00', // Disable olduğunda gri, aktif olduğunda kırmızı
                                        cursor: isDisable() ? 'not-allowed' : 'pointer' // Cursor tıklanamaz olduğunda değişiyor
                                    }}
                                    disabled={isDisable()}
                                    >
                                Qeydiyyatdan keç
                            </button>
                        </form>
                    <div className="hesabiniz-yoxdu-background">
                        <div className="hesabiniz-yoxdu-background-text">Hesabınız var?</div>
                        <div className="hesabiniz-yoxdu-background-text-link"
                             onClick={() => navigate("/login")}>Giriş
                        </div>
                    </div>
                    <div className="hesabiniz-yoxdu-background-patnyor">
                        <div className="hesabiniz-yoxdu-background-text">Əməkdaşlıq etmək istəyirsiniz?
                        </div>
                        <div className="hesabiniz-yoxdu-background-text-link-register"
                             onClick={() => navigate("/partnyor")}>Partnyor ol
                        </div>
                    </div>


                    <div className="copyright">
                        Copyright 2024 Qapinda
                    </div>
                </Card>

            </Box>

        </Grid>
            <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={toastSeverity} sx={{ width: '100%', position:"center"}}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Grid>
    )
};

export default Register;