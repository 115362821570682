import React, {useState} from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import InfoIcon from '@mui/icons-material/Info';
import WorkIcon from '@mui/icons-material/Work'
//drawer elements used
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {setter} from "../../tokenSlice";
import {useDispatch} from "react-redux";
import {useLocation, useNavigate} from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import HistoryIcon from '@mui/icons-material/History';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PrintIcon from '@mui/icons-material/Print';

export default function HamburgerMenu({token, setHeaderStep}) {

    const [open, setState] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState(open);
    };


    const listItemsAuthenticatedPartner = [
        {icon: <HomeIcon sx={{color: 'red'}}/>, text: 'Ana Sehife', path: '/main'},
        {icon: <StoreMallDirectoryIcon sx={{color: 'red'}}/>, text: 'Şöbelerim', path: '/branches'},
        {icon: <HistoryIcon sx={{color: 'red'}}/>, text: 'Geçmiş Sifarişler', path: "/old-orders"},
        {icon: <AddShoppingCartIcon sx={{color: 'red'}}/>, text: 'Sifariş Ver', path: "/create-order"},
        {icon: <ContactMailIcon sx={{color: 'red'}}/>, text: 'Bizimle Elaqe', path: "/contact-us"},
        {icon: <PrintIcon sx={{color: 'red'}}/>, text: 'Z-raporu', path: "z-report"},
    ];

    const listItemsAuthenticatedUser = [
        {icon: <HomeIcon sx={{color: 'red'}}/>, text: 'Ana Sehife', path: '/profile'},
        {icon: <HistoryIcon sx={{color: 'red'}}/>, text: 'Geçmiş Sifarişler', path: "story"},
        {icon: <AddShoppingCartIcon sx={{color: 'red'}}/>, text: 'Sifariş Ver', path: "story"},
        {icon: <ContactMailIcon sx={{color: 'red'}}/>, text: 'Bizimle Elaqe', path: "story"},
    ];

    const listItemsAuthenticatedAdmin = [
        {icon: <HomeIcon sx={{color: 'red'}}/>, text: 'Ana Sehife', path: '/profile'}
    ];

    const listItemsUnAuthenticated = [
        {icon: <HomeIcon sx={{color: 'red'}}/>, text: 'Ana Səhifə', path: 'home'},
        {icon: <ContactMailIcon sx={{color: 'red'}}/>, text: 'Partnyor Ol', path: 'partner'},
        {icon: <AddShoppingCartIcon sx={{color: 'red'}}/>, text: 'Fərdi qeydiyyat', path: 'register'},
        {icon: <PrintIcon sx={{color: 'red'}}/>, text: 'Qiyməti hesabla', path: 'calculate'},
        {icon: <InfoIcon sx={{color: 'red'}}/>, text: 'Haqqımızda', path: 'about'},
        {icon: <WorkIcon sx={{color: 'red'}}/>, text: 'Kuryerimiz olun', path: 'courier'},
    ];

    const logout = () => {
        dispatch(setter({token: null, role: null, userId: null}));
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        localStorage.removeItem('userId');
        navigate('/login');
        window.location.reload();
    }

    return (

        <AppBar position="static" elevation={0}>
            <Container style={{background: "white", shadow: "none"}}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="black"
                        aria-label="open drawer"
                        onClick={toggleDrawer(true)}>
                        <MenuIcon

                        />
                    </IconButton>

                    <Drawer
                        anchor="left"
                        open={open}
                        onClose={toggleDrawer(false)}
                        onOpen={toggleDrawer(true)}
                    >
                        <Box sx={{
                            p: 2,
                            height: 1,
                            backgroundColor: "#ffffff",
                        }}>

                            <IconButton sx={{mb: 2}}>
                                <CloseIcon onClick={toggleDrawer(false)}/>
                                <div className="Logo-mobile-hambuger" onClick={() => navigate("/")}>
                                    Qapında
                                </div>
                            </IconButton>

                            <Divider sx={{mb: 2}}/>

                            <Box sx={{mb: 2}}>
                                {token?.role === "ROLE_CUSTOMER" ?
                                    listItemsAuthenticatedPartner?.map((item, index) => (
                                        <ListItemButton key={index} onClick={() => navigate(item?.path)}>
                                            <ListItemIcon>
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.text}/>
                                        </ListItemButton>
                                    )) : token?.role === "ROLE_USER" ? listItemsAuthenticatedUser?.map((item, index) => (
                                        <ListItemButton key={index} onClick={() => navigate(item?.path)}>
                                            <ListItemIcon>
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.text}/>
                                        </ListItemButton>
                                    )) : token?.role === "ROLE_ADMIN" ? listItemsAuthenticatedAdmin?.map((item, index) => (
                                            <ListItemButton key={index} onClick={() => navigate(item?.path)}>
                                                <ListItemIcon>
                                                    {item.icon}
                                                </ListItemIcon>
                                                <ListItemText primary={item.text}/>
                                            </ListItemButton>
                                        )) :
                                        listItemsUnAuthenticated?.map((item, index) => (
                                            <ListItemButton key={index} onClick={() => {
                                                if (currentPath !== "/") {
                                                    navigate("/")
                                                }
                                                document.getElementById(item.path).scrollIntoView({behavior: 'smooth'})
                                            }}>
                                                <ListItemIcon>
                                                    {item.icon}
                                                </ListItemIcon>
                                                <ListItemText primary={item.text}/>
                                            </ListItemButton>
                                        ))
                                }
                            </Box>

                            {!token ? <Box sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    position: "absolute",
                                    bottom: "0",
                                    left: "45%",
                                    transform: "translate(-45%, 0)"
                                }}
                                >
                                    <button className="qapinda-button-second"
                                            onClick={() => navigate('/partnyor')}>Partnyor Ol
                                    </button>
                                    <button className="qapinda-button" onClick={() => navigate('/login')}>Giriş</button>
                                </Box>
                                :
                                <Box sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    position: "absolute",
                                    bottom: "0",
                                    left: "50%",
                                    transform: "translate(-50%, 0)"
                                }}>
                                    <button className="qapinda-button" onClick={() => logout()}>Çıxış</button>
                                </Box>

                            }

                        </Box>

                    </Drawer>


                </Toolbar>
            </Container>
        </AppBar>

    );
}