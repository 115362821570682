import React, {useEffect, useState} from "react";
import './App.css';
import {Routes, Route, useLocation} from 'react-router-dom'
import Login from "./page/login/Login";
import Register from "./page/register/Register";
import Main from "./page/main/Main";
import {useDispatch, useSelector} from "react-redux";
import Header from "./component/header/Header";
import {setter} from "./tokenSlice";
import Landing from "./page/landing-page/Landing";
import BecomePartner from "./page/partner-become/BecomePartner";
import Footer from "./component/footer/Footer";
import Profile from "./page/main/component/profile/Profile";
import OrderedList from "./page/main/component/ordered-list/OrderedList";
import CreateOrder from "./page/main/component/create-order/CreateOrder";
import {Contacts} from "@mui/icons-material";
import ContactsUs from "./page/main/component/contact-us/ContactsUs";
import ZRapore from "./page/main/component/z-rapore/ZRapore";
import Branches from "./page/main/component/branches/Branches";
import Exception from "./page/exception/Exception";
import AddCustomerAndCreatePackage from "./page/main/component/admin/add-customer/AddCustomerAndCreatePackage";
import AllUserInfo from "./page/main/component/admin/all-user/AllUserInfo";
import ContactUsListForAdmin from "./page/main/component/admin/contact-us/ContactUsListForAdmin";
import SixthBecameCourier from "./page/landing-page/SixthBecameCourier";
import CourierBecomeForm from "./page/courier-become/CourierBecomeForm";
import {useMediaQuery} from "@mui/material";

function App() {
    const token = useSelector((state) => state.token.value)
    const dispatch = useDispatch()
    const isMobile = useMediaQuery('(max-width:1025px)'); // 600px altı mobil kabul ediliyor
    const [headerStep, setHeaderStep] = useState();
    const location = useLocation();
    const currentPath = location.pathname;

    useEffect(() => {
        const savedToken = localStorage.getItem('token');
        const savedRole = localStorage.getItem('role');
        const savedUserId = localStorage.getItem('userId');

        if (savedToken) {
            // Eğer token varsa Redux store'a kaydediyoruz
            dispatch(setter({token: savedToken, role: savedRole, userId: savedUserId}));
        }
    }, [dispatch]);
    return (
        <div>
            { (currentPath !== "/register" && currentPath !== "/login" && currentPath !== "/register" && currentPath !== "/partnyor") && <div className="App">
                <Header setHeaderStep={setHeaderStep} isMobile={isMobile}/>
            </div>}
            <Routes>
                <Route path='/' element={!token ? <Landing isMobile={isMobile}/> :
                    <Main token={token} headerStep={headerStep}/>}/>
                <Route path='/login' element={<Login/>}/>
                <Route path='/register' element={<Register/>}/>
                <Route path='/partnyor' element={<BecomePartner/>}/>
                <Route path='/courier' element={<CourierBecomeForm/>}/>
                <Route path='/main' element={token ? <Main token={token} headerStep={headerStep}/> : <Exception/>}/>
                <Route path='/profile' element={token ? <Profile/> : <Exception/>}/>
                <Route path='/old-orders' element={token ? <OrderedList/> : <Exception/>}/>
                <Route path='/create-order' element={token ? <CreateOrder/> : <Exception/>}/>
                <Route path='/contact-us' element={<ContactsUs/>}/>
                <Route path='/z-report' element={token ? <ZRapore/> : <Exception/>}/>
                <Route path='/branches' element={token ? <Branches/> : <Exception/>}/>
                <Route path='/add-customer'
                       element={token.role === "ROLE_ADMIN" ? <AddCustomerAndCreatePackage/> : <Exception/>}/>
                <Route path='/all-customer' element={token.role === "ROLE_ADMIN" ? <AllUserInfo/> : <Exception/>}/>
                <Route path='/all-user' element={token.role === "ROLE_ADMIN" ? <AllUserInfo/> : <Exception/>}/>
                <Route path='/contact-us/reported-list'
                       element={token.role === "ROLE_ADMIN" ? <ContactUsListForAdmin/> : <Exception/>}/>
            </Routes>
            { (currentPath !== "/register" && currentPath !== "/login" && currentPath !== "/register" && currentPath !== "/partnyor") &&  <div className="footer">
                <Footer/>
            </div>}
        </div>
    );
}

export default App;
