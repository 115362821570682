import React from 'react';
import {Grid} from "@mui/material";
import LoginCard from "./LoginCard";
import LoginImage from "./LoginImage";


const Login = () => {

    return (
        <Grid container spacing={1} className="box-background">
            <Grid item xs={12} sm={12} style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                <LoginCard/>
            </Grid>
        </Grid>
    )
}
export default Login;