import React, { useState } from 'react';
import { Grid, Typography, TextField, Button, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import foto from "../../asset/calculate.jpg";

const StyledGrid = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(3),
    background: 'linear-gradient(45deg, #ffffff,#FFEFEC)',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    borderRadius: '8px',
    height: '70vh',  // Yükseklik ekranla aynı
    margin: 'auto',
}));

const FirthCalculatePage = () => {
    const [estimatedPrice, setEstimatedPrice] = useState("");

    const handleCalculate = () => {
        // Burada tahmini fiyat hesaplaması yapılabilir
        setEstimatedPrice("Tahmini Fiyat: 100 TL");
    };

    return (
        <StyledGrid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={12} md={6}>
                <Typography variant="h5" component="h2" style={{ marginBottom: '20px', color:"black" }}>
                    Fiyat Hesaplayıcı
                </Typography>
                <form>
                    <TextField
                        fullWidth
                        select
                        label="Paket Tipi"
                        defaultValue=""
                        style={{ marginBottom: '20px' }}
                    >
                        <MenuItem value="small">Küçük</MenuItem>
                        <MenuItem value="medium">Orta</MenuItem>
                        <MenuItem value="large">Büyük</MenuItem>
                    </TextField>
                    <TextField
                        fullWidth
                        label="Nereye"
                        defaultValue=""
                        style={{ marginBottom: '20px' }}
                    />
                    <TextField
                        fullWidth
                        label="Nereden"
                        defaultValue=""
                        style={{ marginBottom: '20px' }}
                    />
                    <Button variant="contained" color="primary" fullWidth onClick={handleCalculate}>Hesapla</Button>
                    {estimatedPrice && (
                        <Typography style={{ marginTop: '20px' }}>{estimatedPrice}</Typography>
                    )}
                </form>
            </Grid>
            <Grid item xs={12} md={6}>
                <img src={foto} alt="" style={{
                    width: "100%",
                    height: "auto",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    transition: "box-shadow 0.3s ease-in-out",
                    background:"#fdfcfa"
                }}/>
            </Grid>
        </StyledGrid>
    );
};

export default FirthCalculatePage;