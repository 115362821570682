import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import useCurrentUserInfo from "../../hook/useCurrentUserInfo";
import {Alert, Box, Grid, Paper, Snackbar, TextField, Typography} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import foto from "../../asset/4791.jpg";

const phoneNumber = '+994505610560';

const BecomePartner = () => {
    const token = useSelector((state) => state.token.value);
    const userId = token?.userId;
    const [openToast, setOpenToast] = useState(false); // Toast mesajı için state
    const [toastMessage, setToastMessage] = useState(''); // Toast mesaj içeriği
    const [toastSeverity, setToastSeverity] = useState('success'); // Toast mesaj tipi (success or error)
    const [isSubmitting, setIsSubmitting] = useState(false); // Form gönderilirken butonu disable etmek için
    const {currentUserInfo, loading, error} = useCurrentUserInfo();

    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        message: ''
    });

    useEffect(() => {
        setFormData(
            {
                name: currentUserInfo ? currentUserInfo.firstName + " " + currentUserInfo.lastName : '',
                phone: currentUserInfo ? currentUserInfo.phone : '',
                email: currentUserInfo ? currentUserInfo.email : '',
                message: ''
            }
        )

    }, [currentUserInfo])


    const openWhatsapp = () => {
        const message = 'Salam, Sizinlə partnyor olmaq istəyirəm, mənimlə əlaqə saxlayın.'; // Mesajı buraya ekleyin
        const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappLink, '_blank');
    };

    const callPhone = () => {
        window.open(`tel:${phoneNumber}`);
    };

    // Form verilerini handle eden fonksiyon
    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    // Form gönderim işlemi
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isDisable()) return; // Eğer form eksikse gönderme

        setIsSubmitting(true); // Form gönderilirken butonu disable yap
        try {
            const response = await fetch(userId ? `/contact-us/post?userId=${userId}` : `/contact-us/post-not-user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                setFormData({
                    name: '',
                    phone: '',
                    email: '',
                    message: ''
                }); // Formu sıfırla
                setToastMessage('Mesajınız bizə uğurla çatdırıldı!'); // Başarı mesajı
                setToastSeverity('success'); // Başarı mesajı rengi (yeşil)
                setOpenToast(true); // Toast mesajını göster
            } else {
                setToastMessage('Mesaj göndərilərkən səhv baş verdi. Zəhmət olmasa, yenidən cəhd edin.'); // Hata mesajı
                setToastSeverity('error'); // Hata mesajı rengi (kırmızı)
                setOpenToast(true); // Toast mesajını göster
            }
        } catch (error) {
            console.error('Error sending message:', error);
            setToastMessage('Mesaj gönderilirken bir hata oluştu.'); // Hata mesajı
            setToastSeverity('error'); // Hata mesajı rengi (kırmızı)
            setOpenToast(true); // Toast mesajını göster
        } finally {
            setIsSubmitting(false); // İşlem tamamlandığında butonu tekrar aktif hale getir
        }
    };

    // Form verilerini kontrol eden fonksiyon (input'lar dolu mu kontrol eder)
    const isDisable = () => {
        return !formData.name || !formData.phone  || !formData.message || isSubmitting;
    };

    // Toast mesajını kapatma fonksiyonu
    const handleCloseToast = () => {
        setOpenToast(false);
    };

    return (
        <Box className="box-background">
            <Paper sx={{
                maxWidth: '1200px',
                margin: 'auto',
                padding: 3,
                boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
                border: '2px solid #F4F1DE',
                backgroundColor: '#fff',
            }}>
                <Grid container spacing={3} justifyContent="center" alignItems="center">
                    {/* Başlık ve İletişim Bilgileri */}
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" component="h1" gutterBottom sx={{color: 'red', fontWeight: 'bold'}}>
                            Partnyor Ol
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Bizimle partnyor ol
                        </Typography>

                        <Box sx={{display: 'flex', gap: 2, marginTop: 2, marginBottom: 2}}>
                            <button className="qapinda-button" onClick={() => openWhatsapp()}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <EmailIcon/>
                                    <div style={{paddingLeft: "5px"}}>WhatsApp Dəstəyi</div>
                                </div>
                            </button>
                            <button className="qapinda-button-second" onClick={() => callPhone()}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <PhoneIcon/>
                                    <div style={{paddingLeft: "5px"}}>Zəng edin</div>
                                </div>
                            </button>
                        </Box>
                        <Paper sx={{padding: 3}}>
                            <Typography variant="h6" component="h2" gutterBottom
                                        sx={{}}>
                                Bize melumatlarivi gonder, cox yaxinda sizinle elaqe saxliyaq!
                            </Typography>

                            <form onSubmit={handleSubmit}>
                                <TextField
                                    fullWidth
                                    label="Ad"
                                    placeholder="Adınızı daxil edin"
                                    name="name"
                                    margin="normal"
                                    variant="outlined"
                                    required
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                                <TextField
                                    fullWidth
                                    label="Şirket adı"
                                    placeholder="Şirket adını daxil edin"
                                    name="company"
                                    margin="normal"
                                    variant="outlined"
                                    required
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                                <TextField
                                    fullWidth
                                    label="Telefon"
                                    placeholder="+994505505050"
                                    name="phone"
                                    type="number"
                                    margin="normal"
                                    variant="outlined"
                                    required
                                    value={formData.phone}
                                    onChange={handleChange}
                                />
                                <TextField
                                    fullWidth
                                    label="Email"
                                    placeholder="abc@example.com"
                                    name="email"
                                    type="email"
                                    margin="normal"
                                    variant="outlined"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                <TextField
                                    fullWidth
                                    label="Mesaj"
                                    placeholder="Mesajınızı daxil edin"
                                    name="message"
                                    multiline
                                    rows={4}
                                    margin="normal"
                                    variant="outlined"
                                    required
                                    value={formData.message}
                                    onChange={handleChange}
                                />
                                <button
                                    type="submit"
                                    className="qapinda-button"
                                    style={{
                                        width: "100%",
                                        backgroundColor: isDisable() ? '#ccc' : '#f00', // Disable olduğunda gri, aktif olduğunda kırmızı
                                        cursor: isDisable() ? 'not-allowed' : 'pointer' // Cursor tıklanamaz olduğunda değişiyor
                                    }}
                                    disabled={isDisable()}
                                >
                                    Göndər
                                </button>
                            </form>
                        </Paper>
                    </Grid>

                    {/* Kontakt Formu */}
                    <Grid item xs={12} md={6}>
                        <img src={foto} alt="" style={{
                            width: "100%",
                            height: "auto",
                            maxHeight: "75no0px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            borderRadius: "10px",
                            transition: "box-shadow 0.3s ease-in-out"
                        }}/>
                    </Grid>
                </Grid>
            </Paper>

            {/* Toast mesajı */}
            <Snackbar open={openToast} autoHideDuration={3000} onClose={handleCloseToast}>
                <Alert onClose={handleCloseToast} severity={toastSeverity} sx={{width: '100%'}}>
                    {toastMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default BecomePartner;




// <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
//     <input type='text' value={name} onChange={(e) => setName(e.target.value)} placeholder="İsim" required style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }} />
//     <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }} />
//     <input type='text' value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Telefon" required style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }} />
//     <input type='text' value={companyName} onChange={(e) => setCompanyName(e.target.value)} placeholder="Şirket İsmi (Opsiyonel)" style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }} />
//     <textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Açıklama" required style={{ marginBottom: '10px',  padding: '10px', height: '100px' }} />
//     <button type='submit' className="qapinda-button" style={{ width: '100%' }}>Gönder</button>
// </form>