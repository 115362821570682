import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
    Box,
    Collapse,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Pagination,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import {useSelector} from "react-redux";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "../../component/ordered-list/OrderedList";
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';

const formatOrderTime = (orderTime) => {
    if (!orderTime) return 'Tarih mevcut değil';
    const date = new Date(orderTime);
    return new Intl.DateTimeFormat('tr-TR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    }).format(date);
};

const OrderedList = () => {
    const [activeButton, setActiveButton] = useState('list');
    const [orderedList, setOrderedList] = useState([]);
    const [expanded, setExpanded] = useState(null);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filterOption, setFilterOption] = useState('');
    const [customerNameFilter, setCustomerNameFilter] = useState('');
    const token = useSelector((state) => state.token.value);
    const userId = token?.userId;
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize] = useState(10);

    const handleButtonClick = (button) => {
        setActiveButton(button);
    };

    const fetchOrders = useCallback(async () => {
        try {
            const queryParams = new URLSearchParams({
                page: page,
                size: pageSize,
                customerName: customerNameFilter || '',
                startDate: startDate ? new Date(startDate).toISOString() : '',
                endDate: endDate ? new Date(endDate).toISOString() : ''
            });

            const response = await fetch(`/order/get-list/${userId}?${queryParams.toString()}`, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${token.token}`,
                },
            });
            if (!response.ok) {
                throw new Error("Failed to fetch orders");
            }
            const data = await response.json();
            setOrderedList(data.content);
            setFilteredOrders(data.content);
            setTotalPages(data.totalPages || 1);
        } catch (error) {
            console.error("Error fetching orders:", error);
        }
    }, [userId, token, page, pageSize, customerNameFilter, startDate, endDate]);

    useEffect(() => {
        fetchOrders();
    }, [fetchOrders, page, pageSize]);

    const handleExpandClick = useCallback((id) => {
        setExpanded((prevExpanded) => (prevExpanded === id ? null : id));
    }, []);

    const clearFilters = () => {
        setStartDate(null);
        setEndDate(null);
        setFilterOption('');
        setFilteredOrders(orderedList);
    };

    const isListActive = useMemo(() => activeButton === 'list', [activeButton]);

    return (
        <Box className="box-background">
            <Paper sx={{
                padding: 2,
                height: 'auto',
                backgroundColor: '#ffffff',
                boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
                border: '2px solid #F4F1DE',
                color: '#000000',
                marginBottom: '20px'
            }}>
                <Typography variant="h6" component="div">
                    Tum sifarisleriniz
                </Typography>

                {/* Filter Section */}
                <Grid container spacing={2} sx={{marginTop: 2}}>
                    <Grid item xs={12} className="ordered-list-tablo-list-choose-button">
                        <button
                            className={`toggle-button-list ${activeButton === 'list' ? 'active' : ''}`}
                            style={{width: "100%", height: "100%"}}
                            onClick={() => handleButtonClick('list')}
                        >
                            Liste
                        </button>
                        <button
                            className={`toggle-button ${activeButton === 'table' ? 'active' : ''}`}
                            style={{width: "100%", height: "100%"}}
                            onClick={() => handleButtonClick('table')}
                        >
                            Tablo
                        </button>
                    </Grid>
                    <Grid item xs={12} sm={2.4}>
                        <TextField
                            label="Müşteri Adı"
                            value={customerNameFilter}
                            onChange={(e) => setCustomerNameFilter(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={2.4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Başlangıç Tarihi"
                                value={startDate}
                                onChange={(newValue) => setStartDate(newValue)}
                                renderInput={(params) => <TextField {...params} fullWidth/>}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={2.4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Bitiş Tarihi"
                                value={endDate}
                                onChange={(newValue) => setEndDate(newValue)}
                                renderInput={(params) => <TextField {...params} fullWidth/>}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={2.4}>
                        <FormControl fullWidth>
                            <InputLabel>Filtre Seçimi</InputLabel>
                            <Select
                                value={filterOption}
                                onChange={(e) => setFilterOption(e.target.value)}
                            >
                                <MenuItem value="daily">Günlük</MenuItem>
                                <MenuItem value="weekly">Haftalık</MenuItem>
                                <MenuItem value="monthly">Aylık</MenuItem>
                                <MenuItem value="yearly">Yıllık</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2.4}
                          style={{display: "flex", justifyContent: "center", alignItems: "center"}}>


                        <button className="qapinda-button-second"
                                style={{width: "100%", height: "100%", marginLeft: "5px"}}
                                onClick={clearFilters}
                        >
                            Filtreyi kaldir
                        </button>

                    </Grid>
                </Grid>

                {isListActive ? (
                    filteredOrders.map(({
                                            orderId,
                                            customerName,
                                            customerAddress,
                                            customerPhoneNumber,
                                            orderTime,
                                            orderPrice,
                                            paymentMethod,
                                            orderStatus,
                                            deliveryInstruction
                                        }) => (
                        <Box key={orderId} sx={{
                            marginTop: 2,
                            border: '1px solid #ddd',
                            padding: 2,
                            borderRadius: '8px',
                            background: '#fff'
                        }}>
                            <Box display="flex" justifyContent="space-between">
                                <Typography variant="subtitle1" fontWeight="bold">{customerName}</Typography>
                                <IconButton onClick={() => handleExpandClick(orderId)}>
                                    <ExpandMoreIcon/>
                                </IconButton>
                            </Box>
                            <Collapse in={expanded === orderId} timeout="auto" unmountOnExit>
                                <Typography><strong>Adres:</strong> {customerAddress}</Typography>
                                <Typography><strong>Telefon:</strong> {customerPhoneNumber}</Typography>
                                <Typography><strong>Musteri adi:</strong> {customerName}</Typography>
                                <Typography><strong>Sifaris Tarihi:</strong> {formatOrderTime(orderTime)}</Typography>
                                <Typography><strong>Fiyat:</strong> {orderPrice} AZN</Typography>
                                <Typography><strong>Ödeme Yöntemi:</strong> {paymentMethod}</Typography>
                                <Typography><strong>Sifaris Durumu:</strong> {orderStatus}</Typography>
                                <Typography><strong>Açıklama:</strong> {deliveryInstruction}</Typography>
                            </Collapse>
                        </Box>
                    ))
                ) : (
                    filteredOrders.length !== 0 &&  <TableContainer component={Paper} sx={{marginTop: 2}}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Müşteri Adı</TableCell>
                                    <TableCell>Adres</TableCell>
                                    <TableCell>Telefon</TableCell>
                                    <TableCell>Sipariş Tarihi</TableCell>
                                    <TableCell>Fiyat</TableCell>
                                    <TableCell>Ödeme Yöntemi</TableCell>
                                    <TableCell>Sipariş Durumu</TableCell>
                                    <TableCell>Açıklama</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredOrders.map(({
                                                         orderId,
                                                         customerName,
                                                         customerAddress,
                                                         customerPhoneNumber,
                                                         orderTime,
                                                         orderPrice,
                                                         paymentMethod,
                                                         orderStatus,
                                                         deliveryInstruction
                                                     }) => (
                                    <TableRow key={orderId}>
                                        <TableCell>{customerName}</TableCell>
                                        <TableCell>{customerAddress}</TableCell>
                                        <TableCell>{customerPhoneNumber}</TableCell>
                                        <TableCell>{formatOrderTime(orderTime)}</TableCell>
                                        <TableCell>{orderPrice} AZN</TableCell>
                                        <TableCell>{paymentMethod}</TableCell>
                                        <TableCell>{orderStatus}</TableCell>
                                        <TableCell>{deliveryInstruction}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

                {
                    filteredOrders.length === 0 &&
                    <strong style={{display:"flex", justifyContent:"center", alignItems:"center", paddingTop:"20px"}}>
                        Sizin heç bir sifarişiniz yoxdur.
                    </strong>
                }

                <Box display="flex" justifyContent="center" marginTop={2}>
                    <Pagination
                        count={totalPages}
                        page={page + 1}
                        onChange={(event, value) => setPage(value - 1)}
                    />
                </Box>
            </Paper>
        </Box>
    );
};

export default OrderedList;